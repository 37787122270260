
export function tansParams(params) {
    // let result = ''
    let result = params
    // Object.keys(params).forEach((key) => {
    //  if (!Object.is(params, undefined) && !Object.is(params, null) && !Object.is(JSON.stringify(params), '{}')) {
    //   result += encodeURIComponent(key) + '=' + encodeURIComponent(params[key]) + '&'
    //  }
    // })
    return result
   }