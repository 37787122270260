export default [{
  path: '/login',
  name: 'index-login',
  component: () => import(/* webpackChunkName: "index-login" */ 'v/index/login/Login.vue'),
  meta: {
    title: '登录'
  }
},
{
  path: '/register',
  name: 'index-register',
  component: () => import(/* webpackChunkName: "index-register" */ 'v/index/login/Register.vue'),
  meta: {
    title: '新用户注册'
  }
},
{
  path: '/reset',
  name: 'index-reset',
  component: () => import(/* webpackChunkName: "index-reset" */ 'v/index/login/Reset.vue'),
  meta: {
    title: '重置密码'
  }
}
]
