export default [{
    path: 'personalInfo',
    name: 'info-personalInfo',
    component: () => import('v/index/Info/personalInfo.vue'),
    meta: {
      title: '个人资料'
    }
  },
  {
    path: 'password',
    name: 'info-password',
    component: () => import('v/index/Info/password.vue'),
    meta: {
      title: '修改密码'
    }
  },
]
