export default [
  {
    path: '/active',
    name: 'base-active',
    component: () => import(/* webpackChunkName: "base-active" */ 'v/base/active/Active.vue'),
    meta: {
      title: '活动中心'
    }
  },
  {
    path: '/active/:id',
    name: 'base-active-info',
    component: () => import(/* webpackChunkName: "base-active-info" */ 'v/base/active/ActiveInfo.vue'),
    meta: {
      title: '活动详情'
    }
  },
]
