// export const SERVER_URL = "https://ysslapi.thussat.cn"; // 测试环境
// export const DEVELOPMENT_SERVER_URL = "https://ysslapi.thussat.cn"; //开发环境
// export const SERVER_WS_URL = "wss://ysslapi.thussat.cn"; // websocket

// export const SERVER_URL = "http://123.56.237.167:20202"; // 测试环境
// export const DEVELOPMENT_SERVER_URL = "http://123.56.237.167:20202"; //测试环境
// export const SERVER_WS_URL = "wss://123.56.237.167:20202"; // websocket

// 正式（暂时不用）
// export const SERVER_URL = 'https://api1.testgate.cn' // 正式环境
// export const DEVELOPMENT_SERVER_URL = 'https://api1.testgate.cn' // 开发环境
// export const SERVER_WS_URL = 'wss://api1.testgate.cn' // websocket

// 正式
// export const SERVER_URL = "https://apiy.thuers.com/modules-campsite"; // 正式环境
// export const DEVELOPMENT_SERVER_URL = " https://apiy.thuers.com/modules-campsite"; //开发环境
// export const SERVER_WS_URL = "wss://apiy.thuers.com"; // websocket

// 测试
// export const SERVER_URL = "https://tgwapi.campcenter.cn/modules-campsite"; // 正式环境
// export const DEVELOPMENT_SERVER_URL = "https://tgwapi.campcenter.cn/modules-campsite"; //开发环境
// export const SERVER_WS_URL = "wss://tgwapi.campcenter.cn"; // websocket

// 正式
export const SERVER_URL = "https://gwapi.campcenter.cn/modules-campsite"; // 正式环境
export const DEVELOPMENT_SERVER_URL = "https://gwapi.campcenter.cn/modules-campsite"; //开发环境
export const SERVER_WS_URL = "wss://gwapi.campcenter.cn"; // websocket

// 测试
// export const SERVER_URL = "http:// 8088/modules-campsite"; // 正式环境
// export const DEVELOPMENT_SERVER_URL = "http://43.143.166.79:8088/modules-campsite"; //开发环境
// export const SERVER_WS_URL = "wss://43.143.166.79:8088"; // websocket
