import {setLog} from "../request/index/signUp";
import el from "element-ui/src/locale/lang/el";

//埋点
export function commonApi(number, id) {
  console.log(number, "number");
  setLog({type: number, id: id}).then((res) => {
    console.log(res, "signBack");
    if (res.data.code != 200) {
      return
    }
  }).catch(err => {
    console.log(err, "err");
  });
}

