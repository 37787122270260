export default [{
    path: '/signUp/:type',
    name: 'index-signUp',
    component: () => import('v/index/camp/SignUp.vue'),
    meta: {
      title: '营地报名'
    }
  },
  {
    path: 'homework',
    name: 'index-homework',
    component: () => import('v/index/camp/Homework.vue'),
    meta: {
      title: '营地作业'
    }
    
  },
  // {
  //   path: '/homework/refer',
  //   name: 'homework-refer',
  //   component: () => import('v/index/camp/homework/refer.vue'),
  //   meta: {
  //     title: '提交作业'
  //   },
  // },
  {
    path: '/certificate',
    name: 'index-certificate',
    component: () => import('v/index/camp/Certificate.vue'),
    meta: {
      title: '营地证书'

    }
  }
]
