/* eslint-disable */
import axios from 'axios'
import store from '@/store'
import qs from 'qs'
import {tansParams} from '@/store/common.js'
import {
  SERVER_URL,
  DEVELOPMENT_SERVER_URL
} from '@/config/server'

import {
  Notification,
  Message,
  Loading
} from 'element-ui'
import router from '@/router'

const instance = axios.create({
  baseURL: SERVER_URL,
  timeout: 60000,
  method: "post",
  headers: {
    "Content-Type": "application/x-www-form-urlencoded",
  },
  withCredentials: false,

});

instance.interceptors.request.use(
  (config) => {
    if (config.method === "post" && !config.notQs) {
      config.data = qs.stringify(config.data);
    }
    if (config.url.indexOf(SERVER_URL) === -1) {
      config.headers["Content-Type"] = "multipart/form-data";
    } else {
      config.headers["Content-Type"] = "application/x-www-form-urlencoded";
    }
    // 调用接口请求添加token认证信息
    const token = localStorage.getItem('index-token-all');
    config.headers.token = token
    if (token) config.headers.authorization = token;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default instance;

// 正在进行中的请求列表
const reqList = []

/**
 * 阻止重复请求
 * @param {array} reqList - 请求缓存列表
 * @param {string} url - 当前请求地址
 * @param {function} cancel - 请求中断函数
 * @param {string} errorMessage - 请求中断时需要显示的错误信息
 */
const stopRepeatRequest = function (reqList, url, cancel, errorMessage) {
  const errorMsg = errorMessage || ''
  for (let i = 0; i < reqList.length; i++) {
    if (reqList[i] === url) {
      // cancel(errorMsg);
      return
    }
  }
  reqList.push(url)
}
/**
 * 允许某个请求可以继续进行
 * @param {array} reqList 全部请求列表
 * @param {string} url 请求地址
 */
const allowRequest = function (reqList, url) {
  for (let i = 0; i < reqList.length; i++) {
    if (reqList[i] === url) {
      reqList.splice(i, 1)
      break
    }
  }
}
let loadingInstance = null

export function request(config) {
  const instance = axios.create({
    baseURL: process.env.NODE_ENV === 'development' ?
      DEVELOPMENT_SERVER_URL : SERVER_URL,
    timeout: 60 * 1000
  })
  instance.interceptors.request.use(
    (config) => {
      const loginUrl = '/web/auth/login';
      const setAccountInfo = '/web/register/setAccountInfo';
      const passMsgOld = '/web/auth/changePassOld';
      const getCam = '/web/auth/getCam';
      const LoginCode = '/web/code/loginCode';
      const registerCode = '/web/code/registerCode';
      const registerEmailCode = 'web/code/registerEmailCode';
      const passwordEmailCode = 'web/code/passwordEmailCode';
      const passwordCode = '/web/code/passwordCode';
      const changePassCode = '/web/auth/changePassCode';
      const loginOut = '/web/auth/loginOut';
      const setLog = '/web/auth/setLog';
      if ((config.url.indexOf(loginUrl) != -1 && config.url.indexOf(loginOut) == -1) ||
        config.url.indexOf(setAccountInfo) != -1 ||
        config.url.indexOf(passMsgOld) != -1 ||
        config.url.indexOf(getCam) != -1 ||
        config.url.indexOf(LoginCode) != -1 ||
        config.url.indexOf(registerCode) != -1 ||
        config.url.indexOf(registerEmailCode) != -1 ||
        config.url.indexOf(passwordEmailCode) != -1 ||
        config.url.indexOf(changePassCode) != -1 ||
        config.url.indexOf(passwordCode) != -1 ||
        config.url.indexOf(setLog) != -1)
      {
        return config
      }
      const token = localStorage.getItem('index-token-all');
      if (token) {
        config.headers.token = token;
      } else {
        router.push({
          path: '/login',
          query: {
            redirect: router.currentRoute.fullPath,
            ...store.state.query
          } // 从哪个页面跳转
        })
        const error = new Error('请登录！')
        return Promise.reject(error)
      }

      let cancel
      // 设置cancelToken对象
      config.cancelToken = new axios.CancelToken(function (c) {
        cancel = c
      })
      if (
        config.url.indexOf('/web/config/getUniversity') == -1 &&
        config.url.indexOf('/web/config/getProfession') == -1
      ) {
        // 阻止重复请求。当上个请求未完成时，相同的请求不会进行
        stopRepeatRequest(
          reqList,
          config.url,
          cancel,
          `${config.url} 请求被中断`
        )
      }

      if (config.hasLoading) {
        const loadingText = config.loadingText ? config.loadingText : '请求中'
        loadingInstance = Loading.service({
          lock: true,
          fullscreen: true,
          text: loadingText,
          background: 'rgba(0, 0, 0, 0.25)'
        })
      }
      return config
    },
    (error) => {
      return Promise.reject(error)
    }
  )

  instance.interceptors.response.use(
    (res) => {
      if (res.data.code == 400034 ||
        res.data.code == 400035 ||
        // res.data.code == 400001 ||
        res.data.code == 400002 ||
        res.data.code == 400003) {
        router.push({
          path: '/' + store.state.indexIdentity,
          query: {
            ...store.state.query
          }
        })
        Notification({
          message: res.data.msg,
          duration: 1000,
        });
        return
      }
      if (res.data.code == 400023) {
        let isLogout = res.config.url == '/web/auth/loginOut';
        // 清除本地缓存 除了特殊缓存
        store.commit('removeLocalStorage');
        router.push({
          path: '/' + store.state.indexIdentity,
          query: {
            ...store.state.query
          }
        })
        if (!isLogout) {
          Notification({
            message: res.data.msg,
            duration: 3000,
          });
        }
        return
      }
      if (res.data.code == 400003) {
        // 清除本地缓存 除了特殊缓存
        store.commit('removeLocalStorage');
        router.push({
          path: '/login',
          query: {
            redirect: router.currentRoute.fullPath,
            ...store.state.query
          } // 从哪个页面跳转
        })
      }
      loadingInstance && loadingInstance.close()
      setTimeout(() => {
        allowRequest(reqList, res.config.url)
      }, 1000)
      return res
    },
    (error) => {
      console.log(error, 'err')
      Message.closeAll()
      if (error.msg == '请登录！') {
        router.push({
          path: "/login?code=" + store.state.indexIdentity,
          query: {
            ...store.state.query
          }
        })
      }
      if (error.response.status == 404) {
        Notification({
          message: "营地报名已关闭",
          duration: 3000,
        });
      }
      if (axios.isCancel(error)) {
        console.log(error.msg)
      } else {
        // 增加延迟，相同请求不得在短时间内重复发送
        setTimeout(() => {
          console.log(error)
          allowRequest(reqList, error.config.url)
        }, 1000)
      }
      loadingInstance && loadingInstance.close()
      return Promise.reject(error)
    }
  )
  return instance(config)
}

/**
 * axios:upload 文件上传方法
 * @param url
 * @param data
 * @returns {Promise}
 */
export function upload(url, data = {}) {
  return new Promise((resolve, reject) => {
    axios.post(url, data).then(
      (response) => {
        resolve(response);
      },
      (err) => {
        reject(err);
      }
    );
  });
}

// 通用下载方法
export function download(url, params, filename) {
  console.log(url, params, filename)
  return instance.post(url, params, {
    transformRequest: [
      params => {
        return tansParams(params);
      }
    ],
    headers: {
      "Content-Type": "application/x-www-form-urlencoded"
    },
    responseType: "blob"
  })
    .then(data => {
      const content = data;
      const blob = new Blob([content]);
      if ("download" in document.createElement("a")) {
        const elink = document.createElement("a");
        elink.download = filename;
        elink.style.display = "none";
        elink.href = URL.createObjectURL(blob);
        document.body.appendChild(elink);
        elink.click();
        URL.revokeObjectURL(elink.href);
        document.body.removeChild(elink);
      } else {
        navigator.msSaveBlob(blob, filename);
      }
    })
    .catch(r => {
      console.error(r);
    });
}

// 合并多个请求
export function allRequest(arrRequest) {
  return axios.all(arrRequest)
}
