export default [
  {
    path: '/',
    name: 'base-home',
    component: () => import(/* webpackChunkName: "base-home" */ 'v/base/home/Home.vue'),
    meta: {
      title: '营地中心(CAMP CENTER)-大中衔接高校营地活动中心'
    }
  },
]
