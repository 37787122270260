/* eslint-disable */
import Vue from 'vue'
import Vuex from 'vuex'
import actions from "./actions";
import mutations from "./mutations";
import getters from "./getters";

Vue.use(Vuex)

let state = {
  // 上一页地址
  previousPath: localStorage.getItem("index-previousPath") || "/",
  // 数据
  data: [],
  indexIdentity: localStorage.getItem("index-identity") || "",
  disCode: "",
  human: 0,//清华人文学院
  baseSchool: 0,//基地
  configJson: {}, //分营地填写资料字段
  work_context: "",
  enclosure_url: "",
  isDisplay_num: null,//是否开启报名人数显示
  info: {},
  isCloseCamp: 0,//是否关闭营地
  signUpInfo: {},
  token: localStorage.getItem("index-token-all") || "",
  isLogin: localStorage.getItem("isLogin") || "0", // 只有1为已登录
  activeIndex: window.localStorage.getItem('index-active-path') || "0",
  query: {},
};

export default new Vuex.Store({
  state,
  mutations,
  actions,
  getters,
});
