<template>
  <div id="app">
    <router-view v-if="isRouterAlive"/>
  </div>
</template>
<script>
  /* eslint-disable */
  export default {
    name: "app",
    data() {
      return {
        isRouterAlive: true
      };
    },
    created() {
      this.$store.commit('setActiveIndex', this.$route.path)
    },
    methods: {
      reload() {
        this.isRouterAlive = false
        this.$nextTick(() => (this.isRouterAlive = true))
      }
    },
    watch: {
      '$route'(val) {
        this.$store.commit('setActiveIndex', val.path)
      }
    }
  };
</script>
<style lang="scss">
  @import "a/scss/index";
  // @import "~a/css/base.css";
  #app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    position: relative;
    height: 100vh;
    background: #fff;
    // background: #f8f8f8;
  }

  //滚动条的宽度
  ::-webkit-scrollbar {
    width: 12px;
    height: 12px;
  }

  //滚动条的滑块
  ::-webkit-scrollbar-thumb {
    background-color: #cccccc;
    border-radius: 4px;
  }

  .el-dropdown-menu {
    .el-dropdown-menu__item {
      padding: 0 30px !important;

      &:hover {
        color: #ffffff !important;
        background-color: var(--color) !important;
      }

      i {
        font-size: 19px;
      }
    }
  }

  .el-message-box {
    width: 360px !important;

    .el-message-box__header {
      .el-message-box__title {
        color: red;
      }
      .el-message-box__headerbtn {
        &:focus,
        &:hover {
          .el-message-box__close {
            color: var(--color);
          }
        }
      }
    }

    .el-button {
      &:nth-child(1):hover {
        color: var(--color);
        background-color: #ffffff;
        border-color: var(--color);
        opacity: 0.6;
      }

      &:nth-child(2) {
        color: #fff;
        background-color: var(--color);
        border-color: var(--color);

        &:hover {
          color: #fff;
          background-color: var(--color);
          border-color: var(--color);
          opacity: 0.9;
        }
      }
    }
  }
</style>
