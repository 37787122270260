/* eslint-disable */
import {request} from '../network';
import store from '@/store';

//登录前获取营地主题
export function getCam(code) {
  let data = {};
  data['identity'] = code ? code : store.state.indexIdentity;
  if (!data['identity']) {
    return false;
  }
  return request({
    method: 'get',
    url: '/web/auth/getCam',
    params: data
  })
}

export function getCamInfo(code) {
  let data = {};
  data['identity'] = code ? code : store.state.indexIdentity;
  if (!data['identity']) {
    return false;
  }
  return request({
    method: 'get',
    url: '/web/auth/getCamInfo',
    params: data
  })
}

// 登录
export function login(data) {
  data['identity'] = store.state.indexIdentity;
  data['disCode'] = store.state.disCode;
  return request({
    method: 'post',
    url: '/web/auth/login',
    params: data

  })
}

// 获取登录验证码
export function loginCode(data) {
  data['identity'] = store.state.indexIdentity;
  return request({
    method: 'post',
    url: '/web/code/loginCode',
    params: data

  })
}

// 退出
export function logout(data) {
  const token = localStorage.getItem('index-token-all');
  data['identity'] = store.state.indexIdentity;
  return request({
    headers: {
      token: token
    },
    method: 'post',
    url: '/web/auth/loginOut',
    data
  })
}

// 通过旧密码找回密码
export function changePassOld(data) {
  data['identity'] = store.state.indexIdentity;
  return request({
    method: 'post',
    url: '/web/auth/changePassOld',
    data
  })
}

// 通过验证码找回密码
export function changePassCode(data) {
  data['identity'] = store.state.indexIdentity;
  return request({
    method: 'post',
    url: '/web/auth/changePassCode',
    data
  })
}

