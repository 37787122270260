export default [{
  path: '/baseLogin',
  name: 'base-login',
  component: () => import(/* webpackChunkName: "base-login" */ 'v/base/login/Login.vue'),
  meta: {
    title: '登录'
  }
},
{
  path: '/baseRegister',
  name: 'base-register',
  component: () => import(/* webpackChunkName: "base-register" */ 'v/base/login/Register.vue'),
  meta: {
    title: '新用户注册'
  }
},
{
  path: '/baseReset',
  name: 'base-reset',
  component: () => import(/* webpackChunkName: "reset" */ 'v/base/login/Reset.vue'),
  meta: {
    title: '重置密码'
  }
}
]
