/* eslint-disable */
import {request} from '../network';
import store from '@/store';

// 报名初始化
export function signUpInit(data) {
  data['identity'] = store.state.indexIdentity;
  data['disCode'] = store.state.disCode;
  return request({
    method: 'post',
    url: '/web/signUp/signUpInit',
    params: data
  })
}

// 状态回退
export function signBack(data) {
  data['identity'] = store.state.indexIdentity;
  data['disCode'] = store.state.disCode;
  return request({
    method: 'post',
    url: '/web/signUp/signBack',
    params: data
  })
}

// 确认报名须知
export function setSignShould(data) {
  data['identity'] = store.state.indexIdentity;
  data['disCode'] = store.state.disCode;
  return request({
    method: 'post',
    url: '/web/signUp/setSignShould',
    params: data
  })
}

// 绑定邀请码
export function setInviteCode(data) {
  data['identity'] = store.state.indexIdentity;
  data['disCode'] = store.state.disCode;
  return request({
    method: 'post',
    url: '/web/signUp/setInviteCode',
    params: data
  })
}

// 页面个人获取
export function getAccount(data) {
  data['identity'] = store.state.indexIdentity;
  data['disCode'] = store.state.disCode;
  return request({
    method: 'post',
    url: '/web/signUp/getAccount',
    params: data
  })
}

// 获取学习成绩证明材料上传图片
export function getAchievementOss(data) {
  data['identity'] = store.state.indexIdentity;
  return request({
    method: 'post',
    url: '/web/signUp/getAchievementOss',
    data
  })
}

//删除图片
export function delPic(data) {
  data['identity'] = store.state.indexIdentity;
  return request({
    method: 'post',
    url: '/web/auth/delPic',
    params:data
  })
}

// 上传缴费图片
export function getBankInfoOss(data) {
  data['identity'] = store.state.indexIdentity;
  return request({
    method: 'post',
    url: '/web/signUp/getBankInfoOss',
    data
  })
}

// 删除缴费图片
export function delPayingPic(data) {
  data['identity'] = store.state.indexIdentity;
  return request({
    method: 'post',
    url: '/web/signUp/delPic',
    params: data
  })
}

// 页面个人信息保存
export function setAccount(data) {
  data['identity'] = store.state.indexIdentity;
  data['disCode'] = store.state.disCode;
  return request({
    method: 'post',
    url: '/web/signUp/setAccount',
    params: data
  })
}

// 获取报名省份城市
export function getProAndCity(data) {
  data['identity'] = store.state.indexIdentity;
  return request({
    method: 'post',
    url: '/web/signUp/getProAndCity',
    params: data
  })
}

// 获取报名学校
export function getSchool(data) {
  data['identity'] = store.state.indexIdentity;
  return request({
    method: 'post',
    url: '/web/signUp/getSchool',
    params: data
  })
}


//埋点
export function setLog(data) {
  data['identity'] = store.state.indexIdentity;
  data['disCode'] = store.state.disCode;
  return request({
    method: 'post',
    url: '/web/auth/setLog',
    params: data
  })
}
