/* eslint-disable */

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import '@/assets/scss/index.scss'
import {
  download,
  upload
} from "r/index/network.js";
import 'default-passive-events'

Vue.config.devtools = true;
Vue.config.productionTip = false

// 弹出框的时候滚动条隐藏和出现导致页面抖动问题
ElementUI.Dialog.props.lockScroll.default = false;
Vue.use(ElementUI)

Vue.prototype.download = download
Vue.prototype.upload = upload

// 防止重复点击自定义属性
import preventReClick from './utils/preventRepeatClick.js';
Vue.use(preventReClick);

//埋点公共方法
import {commonApi}from './utils/common';
import {formatYMD, formatYMDChina}from './store/time';
Vue.prototype.commonApi = commonApi;
Vue.prototype.formatYMD = formatYMD;
Vue.prototype.formatYMDChina = formatYMDChina;

// 禁止浏览器前进后退 另一部本在router的index.js中
window.addEventListener('popstate', function() {
  history.pushState(null, null, document.URL)
})

Vue.prototype.$errorScroll = function errorScroll(callback) {
  this.$nextTick(() => {
    // this.loading = false;
    // let isError = document.getElementsByClassName('is-error')
    // this.$api.error(isError[0].innerText);
    // isError[0].scrollIntoView({
    //   //  滚动到指定节点
    //   // 值有start,center,end，nearest，当前显示在视图区域中间
    //   block: 'center',
    //   // 值有auto、instant,smooth，缓动动画（当前是慢速的）
    //   behavior: 'smooth',

    // })


    if (document.getElementsByClassName('el-form-item__error').length > 0) {
      this.$notify.error({
        title: '提示',
        message: document.getElementsByClassName('el-form-item__error')[0].innerText
      });
    }

    // setTimeout(()=>{
    //   var isError= document.getElementsByClassName("is-error");
    //   isError[0].querySelector('input').focus();
    // },100);
    // return false;

  })
}
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
