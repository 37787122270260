/* eslint-disable */
export default {
  // 更新上一页信息
  setPrePage(state, path) {
    state.previousPage = path;
  },
  // 保存数据
  setData(state, city) {
    state.data = city;
  },
  login(state, token) {
    state.isLogin = "1";
    state.token = token;
    localStorage.setItem("isLogin", "1");
    localStorage.setItem("index-token-all", token);
  },
  loginOut(state) {
    state.isLogin = "0";
    state.token = "";
    localStorage.removeItem("isLogin");
    localStorage.removeItem("index-token-all");
  },
  setIndexIdentity(state, code) {
    state.indexIdentity = code;
    window.localStorage.setItem('index-identity', code);
  },
  setDisCode(state, code) {
    state.disCode = code;
    state.query['disCode'] = code;
    // window.localStorage.setItem('index-disCode', code);
  },
  setActiveIndex(state, path) {
    let key = path.split('/')[1] == 'active' ? '/' + path.split('/')[1] : path;
    state.activeIndex = key;
    window.localStorage.setItem('index-active-path', key);
  },
  setPreviousPath(state, path) {
    state.previousPath = path;
    window.localStorage.setItem('index-previousPath', path);
  },
  removeLocalStorage(state) {
    for (let key in window.localStorage) {
      if (key.indexOf('email') == -1 &&
        key.indexOf('multiform') == -1 &&
        key.indexOf('register_code_time') == -1 &&
        key.indexOf('restet_code_phone') == -1 &&
        key.indexOf('restet_code_time') == -1 &&
        key.indexOf('index-active-path') == -1 &&
        key.indexOf('index-fullPath') == -1) {
        window.localStorage.removeItem(key);
      }
    }
    state.isLogin = "0";
    state.token = "";
  },
};
